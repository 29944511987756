.btn {
  padding: 8px 20px;
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--maincolor2);
  color: var(--maincolor1);
  border: 1px solid var(--maincolor2);
}
.btn--primary:hover {
  padding: 8px 20px;
  transition: all 0.5s ease-out;
  background: var(--maincolor1);
  color: var(--maincolor2);
  border: 1px solid var(--maincolor2);
  transition: 250ms;
}

.btn--outline {
  background-color: transparent;
  color: var(--maincolor2);
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--maincolor2);
  transition: all 0.5s ease-out;
}
.btn--outline:hover {
  background-color: var(--maincolor2);
  color: var(--maincolor1);
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid var(--maincolor2);
  transition: all 0.5s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 18px;
}

.btn--medium {
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  border-radius: 10px;
  font-size: 20px;
}
