:root {
  --maincolor1: #ffffff;
  --maincolor2: #0070c0;
  --title: #2e2e2e;
  --text: #8e8d8a;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

header {
  position: fixed;
  top: 0px;
  background-color: var(--maincolor2);
  width: 100%;
  z-index: 1000;
  height: 45px;
}

html {
  font-size: 62.5%;
}
.Content {
  max-width: 1600px;
}
@media screen and (max-width: 1500px) {
  html {
    font-size: 60%;
  }
  .Content {
    max-width: 1400px;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 50%;
  }
  .Content {
    max-width: 1200px;
  }
}

@media screen and (max-width: 960px) {
  html {
    font-size: 48%;
  }
  .Content {
    max-width: 900px;
  }
}
