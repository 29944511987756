.main-container-enquiry {
  max-width: 100rem;
  padding: 0 4rem;
  margin: 0 auto;
  margin-top: 10rem;
}
.input-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.enquiry-col-50 {
  width: 50%;
}
.enquiry-col-50-right {
  width: 50%;
}
.enquiry-textbox {
  width: 100%;
  font-size: 1.3rem;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 5px;
  padding-top: 5px;
  margin-bottom: 2rem;
}
.enquiry-title-container {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.enquiry-message-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.enquiry-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 800px) {
  .enquiry-col-50 {
    width: 100%;
    margin-bottom: 2rem;
  }
  .enquiry-col-50-right {
    width: 100%;
    margin-bottom: 2rem;
  }
  .input-container {
    margin-bottom: 0;
  }
}
