.main-container {
  max-width: 160rem;
  padding: 0 4rem;
  margin: 0 auto;
  margin-top: 10rem;
}
.container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15rem;
}
.container-col-50 {
  width: 50%;
}
.content-container1 {
  display: flex;
  justify-content: right;
}
.content-container2 {
  display: flex;
  flex-direction: column;
  justify-content: right;
}
.container-image {
  width: 45rem;
}

@media (max-width: 800px) {
  .container-col-50 {
    width: 100%;
  }
  .content-container1 {
    justify-content: center;
    margin-bottom: 5rem;
  }
  .content-container2 {
    margin-bottom: 5rem;
  }
  .container-imagee {
    width: 25rem;
  }
}
