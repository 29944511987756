.row {
  display: flex;
  flex-wrap: wrap;
}

.row-border {
  border-bottom: 1px solid var(--maincolor1);
  margin-bottom: 80px;
  padding-bottom: 5px;
}

.footer {
  background-color: var(--maincolor2);
  padding: 70px 0;
  margin-top: 300px;
}
.footer-col-50 {
  width: 50%;
  padding: 0 15px;
  text-align: center;
  color: var(--maincolor1);
}

.footer-col-100 {
  width: 100%;
  padding: 0 15px;
  text-align: center;
  color: var(--maincolor1);
}

.footer-col-50 h2 {
  font-weight: 600;
}

.footer-col {
  width: 33%;
  padding: 0 15px;
}
.footer-col-center {
  text-align: center;
}
.footer-col-right {
  text-align: right;
}
.footer .footer-container {
  max-width: 160rem;
  padding: 0 4rem;
  margin: 0 auto;
}
.footer-col ul {
  list-style: none;
  padding-left: 0;
}

.footer-col h3 {
  font-size: 20px;
  color: var(--maincolor1);
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 600;
  position: relative;
}

.footer-col h4 {
  font-size: 18px;
  color: var(--maincolor1);
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 400;
  position: relative;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  text-transform: lowercase;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover {
  color: var(--maincolor1);
  padding-left: 8px;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  color: #24262b;
  background-color: var(--maincolor1);
}

/*responsive*/
@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .row-border {
    padding-bottom: 50px;
  }
  .footer-col {
    width: 100%;
    text-align: center;
  }
  .footer-col-right {
    text-align: center;
  }

  .footer-col-50 {
    width: 100%;
    margin-top: 30px;
  }

  .footer-col ul {
    padding-left: 0;
  }

  .footer-col h3 {
    margin-bottom: 15px;
  }
}
