.home-tag {
  background-color: var(--maincolor2);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-tag h2 {
  font-weight: 800;
  color: var(--maincolor1);
}
.home-components {
  max-width: 160rem;
  padding: 0 4rem;
  margin: 0 auto;
}
.home-components section {
  margin-top: 250px;
}
.home-title {
  font-weight: 800;
  color: var(--title);
}
.home-text {
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--text);
}
.home-about {
  display: flex;
  flex-wrap: wrap;
}
.home-services {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home-col-50 {
  width: 50%;
}

.home-col-28 {
  width: 28%;
}
.home-col-20 {
  width: 20%;
}
.about-image-container {
  display: flex;
  justify-content: right;
}
.about-home-image {
  width: 45rem;
}
.service-title-container {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-card {
  width: 100%;
  height: 70rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50px;
}
.home-industries {
  margin-top: 350px;
}
.industry-icons {
  font-size: 35px;
  color: var(--maincolor2);
}
.each-industry {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.clientscontainer span {
  color: #01111d;
}

.logo-client {
  width: 200px;
  height: 100px;
  float: left;
  padding: 5px 10px;
}
.Smain {
  margin: auto;
  background-color: #e3e3e3;
  width: 100%;
  height: 150px;
  overflow: hidden;
}
.slidermain {
  padding: 20px;
  width: 200%;
  position: relative;
  animation: slider 25s infinite;
  float: left;
}

@keyframes slider {
  0% {
    left: 0%;
  }
  10% {
    left: -0%;
  }
  15% {
    left: -0%;
  }
  20% {
    left: -5%;
  }
  30% {
    left: -10%;
  }
  40% {
    left: -15%;
  }
  50% {
    left: -15%;
  }
  55% {
    left: -10%;
  }
  60% {
    left: -5%;
  }
  70% {
    left: -0%;
  }
  80% {
    left: -0%;
  }
  90% {
    left: 0%;
  }
}
@media (max-width: 800px) {
  .home-col-50 {
    width: 100%;
  }
  .home-col-28 {
    width: 100%;
  }
  .about-image-container {
    justify-content: center;
  }
  .about-home-image {
    width: 25rem;
  }

  .home-tag h2 {
    font-size: 1.5rem;
  }
  .service-card {
    width: 100%;
    height: 80rem;
    margin-bottom: 80px;
  }
  .each-industry {
    justify-content: space-around;
  }
  .Smain {
    height: 230px;
  }
}

@media (max-width: 500px) {
  .Smain {
    height: 330px;
  }
}
