#bootstrap-overrides .container {
  background-color: var(--maincolor2);
  width: 100%;
  z-index: 1000;
  max-width: 160rem;
  padding: 0 4rem;
  margin: 0 auto;
  display: flex;
  position: relative;
  height: 45px;
}

header {
  height: 45px;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--maincolor2);
  transition: transform 500ms ease-in-out;
}

header.nav-bar--hidden {
  transform: translateY(-100%);
}

#bootstrap-overrides .logo-container {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: fit-content;
}

#bootstrap-overrides .nav-btn {
  flex: 2;
  display: flex;
}

#bootstrap-overrides .nav-links {
  flex: 1;
}

#bootstrap-overrides .logo {
  color: var(--maincolor1);
  font-size: 1.9rem;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 3rem;
  cursor: pointer;
}

#bootstrap-overrides .nav-links > ul {
  height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#bootstrap-overrides .nav-link {
  position: relative;
}

#bootstrap-overrides .nav-link > .nav-link-a {
  line-height: 3rem;
  color: var(--maincolor1);
  padding: 1.8rem;
  letter-spacing: 1px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s;
}

#bootstrap-overrides .nav-link > .nav-link-a > i {
  margin-left: 0.2rem;
}

#bootstrap-overrides .nav-link:hover > .nav-link-a {
  transform: scale(1.1);
  font-weight: 400;
}

#bootstrap-overrides .nav-link > .nav-link-a-selected {
  line-height: 3rem;
  color: var(--maincolor1);
  padding: 1.8rem;
  letter-spacing: 1px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s;
  transform: scale(1.1);
  font-weight: 400;
  text-decoration: underline;
  text-underline-offset: 5px;
}

#bootstrap-overrides .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 30rem;
  transform: translateY(10px);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

#bootstrap-overrides .dropdown ul {
  position: relative;
}

#bootstrap-overrides .dropdown-link > .dropdown-link-a {
  display: flex;
  background-color: var(--maincolor1);
  color: var(--maincolor2);
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
}

#bootstrap-overrides .dropdown-link:hover > .dropdown-link-a {
  background-color: var(--maincolor2);
  color: var(--maincolor1);
}

#bootstrap-overrides .dropdown-link:not(:nth-last-child(2)) {
  border-bottom: 1px solid var(--maincolor1);
}

#bootstrap-overrides .dropdown-link i {
  transform: rotate(-90deg);
}

#bootstrap-overrides .arrow {
  position: absolute;
  width: 11px;
  height: 11px;
  top: -5.5px;
  left: 32px;
  background-color: var(--maincolor1);
  transform: rotate(45deg);
  cursor: pointer;
  transition: 0.3s;
  z-index: -1;
}

#bootstrap-overrides .dropdown-link:first-child:hover ~ .arrow {
  background-color: var(--maincolor2);
}

#bootstrap-overrides .dropdown-link {
  position: relative;
}

#bootstrap-overrides .dropdown.second {
  top: 0;
  left: 100%;
  padding-left: 0.8rem;
  cursor: pointer;
  transform: translateX(10px);
}

#bootstrap-overrides .dropdown.second .arrow {
  top: 10px;
  left: -5.5px;
}

#bootstrap-overrides .nav-link:hover > .dropdown,
#bootstrap-overrides .dropdown-link:hover > .dropdown {
  transform: translate(0, 0);
  opacity: 1;
  pointer-events: auto;
}

#bootstrap-overrides .hamburger-menu-container {
  flex: 1;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

#bootstrap-overrides .hamburger-menu {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#bootstrap-overrides .hamburger-menu div {
  width: 2rem;
  height: 2px;
  border-radius: 3px;
  background-color: var(--maincolor1);
  position: relative;
  z-index: 1001;
  transition: 0.5s;
}

#bootstrap-overrides .hamburger-menu div:before,
#bootstrap-overrides .hamburger-menu div:after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: var(--maincolor1);
  border-radius: 3px;
  transition: 0.5s;
}

#bootstrap-overrides .hamburger-menu div:before {
  transform: translateY(-7px);
}

#bootstrap-overrides .hamburger-menu div:after {
  transform: translateY(7px);
}

#bootstrap-overrides #check {
  position: absolute;
  top: 50%;
  right: 3.5rem;
  transform: translateY(-50%);
  width: 2.5rem;
  height: 2.5rem;
  z-index: 90000;
  cursor: pointer;
  opacity: 0;
  display: none;
}

#bootstrap-overrides
  #check:checked
  ~ .hamburger-menu-container
  .hamburger-menu
  div {
  background-color: transparent;
}

#bootstrap-overrides
  #check:checked
  ~ .hamburger-menu-container
  .hamburger-menu
  div:before {
  transform: translateY(0) rotate(-45deg);
}

#bootstrap-overrides
  #check:checked
  ~ .hamburger-menu-container
  .hamburger-menu
  div:after {
  transform: translateY(0) rotate(45deg);
}

@keyframes animation {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (max-width: 920px) {
  #bootstrap-overrides .hamburger-menu-container {
    display: flex;
  }

  #bootstrap-overrides #check {
    display: block;
  }

  #bootstrap-overrides .nav-btn {
    position: fixed;
    height: calc(100vh - 3rem);
    top: 5rem;
    left: 0;
    width: 100%;
    background-color: var(--maincolor2);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(100%);
    transition: 0.65s;
  }

  #bootstrap-overrides #check:checked ~ .nav-btn {
    transform: translateX(0);
  }

  #bootstrap-overrides #check:checked ~ .nav-btn .nav-link,
  #bootstrap-overrides #check:checked ~ .nav-btn .log-sign {
    animation: animation 1s ease forwards;
  }

  #bootstrap-overrides .nav-links {
    flex: initial;
    width: 100%;
  }

  #bootstrap-overrides .nav-links > ul {
    flex-direction: column;
    display: block;
  }

  #bootstrap-overrides .nav-link {
    width: 100%;
    opacity: 0;
    transform: translateY(15px);
  }

  #bootstrap-overrides .nav-link > .nav-link-a {
    line-height: 1;
    padding: 1.6rem 2rem;
  }

  #bootstrap-overrides .nav-link:hover > .nav-link-a {
    transform: scale(1);
    background-color: var(--maincolor1);
    color: var(--maincolor2);
    text-decoration: none;
  }

  #bootstrap-overrides .nav-link > .nav-link-a-selected {
    line-height: 1;
    padding: 1.6rem 2rem;
    transform: scale(1);
    background-color: var(--maincolor1);
    color: var(--maincolor2);
    text-decoration: none;
  }

  #bootstrap-overrides .dropdown,
  .dropdown.second {
    position: initial;
    top: initial;
    left: initial;
    transform: initial;
    opacity: 1;
    pointer-events: auto;
    width: 100%;
    padding: 0;
    background-color: var(--maincolor2);
    display: none;
  }

  #bootstrap-overrides .nav-link:hover > .dropdown,
  #bootstrap-overrides .dropdown-link:hover > .dropdown {
    display: block;
  }

  #bootstrap-overrides .nav-link:hover > .nav-link-a > i,
  #bootstrap-overrides .dropdown-link:hover > .dropdown-link-a > i {
    transform: rotate(360deg);
  }

  #bootstrap-overrides .dropdown-link > .dropdown-link-a {
    background-color: var(--maincolor1);
    color: var(--maincolor2);
    padding: 1.2rem 2rem;
    line-height: 1;
  }

  #bootstrap-overrides .dropdown-link:hover > .dropdown-link-a {
    background-color: var(--maincolor2);
    color: var(--maincolor1);
  }

  #bootstrap-overrides .dropdown.second .dropdown-link > .dropdown-link-a {
    padding: 1.2rem 2rem 1.2rem 3rem;
  }

  #bootstrap-overrides
    .dropdown.second
    .dropdown.second
    .dropdown-link
    > .dropdown-link-a {
    padding: 1.2rem 2rem 1.2rem 4rem;
  }

  #bootstrap-overrides .dropdown-link:not(:nth-last-child(2)) {
    border-bottom: none;
  }

  #bootstrap-overrides .arrow {
    z-index: 1;
    background-color: var(--maincolor1);
    left: 10%;
    transform: scale(1.1) rotate(45deg);
    transition: 0.5s;
  }

  #bootstrap-overrides .nav-link:hover .arrow {
    background-color: var(--maincolor1);
  }

  #bootstrap-overrides .dropdown .dropdown .arrow {
    display: none;
  }

  #bootstrap-overrides .dropdown-link:hover > .dropdown-link-a {
    background-color: var(--maincolor2);
  }

  #bootstrap-overrides .dropdown-link:first-child:hover ~ .arrow {
    background-color: var(--maincolor2);
  }

  #bootstrap-overrides .nav-link > .nav-link-a > i {
    font-size: 1.1rem;
    transform: rotate(-90deg);
    transition: 0.7s;
  }

  #bootstrap-overrides .dropdown i {
    font-size: 1rem;
    transition: 0.7s;
  }

  #bootstrap-overrides .log-sign {
    flex: initial;
    width: 100%;
    padding: 1.5rem 1.9rem;
    justify-content: flex-start;
    opacity: 0;
    transform: translateY(15px);
  }
}
