.carousel-caption-cs {
  transform: translateY(-30rem);
  bottom: initial;
}

@media (max-width: 920px) {
  .carousel-caption-cs {
    transform: translateY(-20rem);
  }

  .carousel-caption-cs h1 {
    font-size: 16px;
  }

  .carousel-caption-cs h2 {
    font-size: 10px;
  }
}

@media (max-width: 500px) {
  .carousel-caption-cs {
    transform: translateY(-12rem);
  }

  .carousel-caption-cs h1 {
    font-size: 12px;
  }

  .carousel-caption-cs h2 {
    font-size: 8px;
  }
}
